@import '../globals/texts';

//label para alineacion con input
.dav- {
  &formElement {
    &__label {
      margin-left: 8px;
      @extend %defaultFont;
      font-size: var(--font-xs);
      font-weight: var(--fw-medium);
    }

    &__additionalLabel {
      @extend %defaultFont;
      font-size: var(--font-3xs);
      color: var(--gray-6765);
    }
  }
}