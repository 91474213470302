//Titulos genericos para todos los elementos

%defaultFont {
  font-family: var(--roboto);
  font-size: var(--font-xs);
  line-height: 130%;
  font-weight: var(--fw-regular);
  text-align: left;
  font-style: normal;
  color: var(--text-color);
}

//Estilos globales de textfields

%textFieldGeneral {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 8px;
  border: 1px solid var(--gris-003);
  @extend %defaultFont;
  font-size: 16px;
  color: var(--negro-800);
  border-radius: 8px;
  background-color: var(--gris-007);
  transition: all .2s ease;

  &:focus-within {
    @extend %active;
  }

  input {
    width: 100%;
  }

  &:disabled {
    @extend %disabled;
  }

  &::placeholder {
    color: var(--gray-6765);
  }

  &:-ms-reveal,
  &:-ms-clear {
    display: none;
  }
}

%buttonAction {
  display: flex;
  align-items: center;
  height: 40px;
  position: absolute;
  opacity: 0.8;
  right: 8px;
  bottom: 0;

  img {
    display: inline-block;
    width: auto;
    cursor: pointer;
  }
}

%active {
  border: 1px solid var(--gris-001);
  background-color: var(--gris-007);
  color: var(--negro-800);

  input {
    background-color: inherit;
    color: inherit;
  }
}

%error {
  @include prefix((border: 1px solid var(--rojo-900),
    appearance: none,
  ), webkit moz ms o);
}

%disabled {
  background-color: var(--background-disabled);
  color: var(--text-disabled);
  cursor: not-allowed;
  border: 1px solid var(--border-disabled);
  background-color: var(--background-disabled);

  input {
    cursor: inherit;
    pointer-events: none;
  }
}

%editable {
  padding: 8px 40px 8px 8px;
  background-color: var(--gris-007);
  border: 1px solid var(--gris-003);
  color: var(--gray-6765);
  cursor: not-allowed;

  input {
    cursor: inherit;
    pointer-events: none;
  }
}

