//Helper icon text

//contenedor
.dav- {
  &helper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 8px;
    &__icon {
      //margin-top: 4px;
      margin-left: 9px;
      margin-right: 7px;
    }
    &__text {
      position: relative;
      @extend %defaultFont;
      font-size: var(--font-2xs);
      color: var(--gray-6765);

      &--truncateText {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    &__contentBox {
      border-radius: 8px;
      padding: 8px 16px;
    }
    &--type2 {
      @extend .dav-helper;
      background-color: var(--white-fa);
      border-radius: 8px;
      max-height: 0;
      transition: max-height 0.3s ease;
      overflow: hidden;
      margin: 0 0 8px 0;
      .dav-helper__text {
        @extend %defaultFont;
        color: var(--negro-800);
      }
      .dav-helper__contentBox {
        border-radius: 8px;
        padding: 8px 16px;
      }
    }

    &--showMore {
      @extend .dav-helper;
      flex-direction: column;

      .dav- {
        &helper {
          &__more {
            @extend .dav-helper__text;
            text-decoration: underline;
            cursor: pointer;
            @extend %defaultFont;
            font-weight: var(--fw-medium);
          }
        }
      }
      .viewContent {
        -webkit-line-clamp: initial;
      }
    }
  }
}

.show {
  max-height: 900px;
  transition: max-height 0.3s ease;
}
