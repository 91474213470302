@import './texts';
@import './layouts';
@import './swiper';
@import './extends';


.dav-tittleResults{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
}
