.dav-container {
  display: block;
  width: 100%;
  height: 100%;
  max-width: 536px;
  margin: 0 auto;
  padding: 0;
  position: relative;
}

.dav-generalContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 8px;
  padding-right: 8px;
}

.dav-generalContainer--typeB {
  @extend .dav-generalContainer;
  gap: 8px;
}

.dav-titleContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0px 16px;
}

.dav-separator {
  content: '';
  height: 1px;
  background-color: #D9D9D9;
  width: 100%;
}

.dav-contentBox {
  background-color: #fff;
  width: calc(100% - 16px);
  padding: 16px;
  border-radius: 8px;
  margin: 0 auto;
}

.dav-boxTittle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.dav-contButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  padding-bottom: 24px;

  .sc-dav-button-h {
    width: auto;
  }
}

.dav-bgResults {
  background: #870512;
  background: linear-gradient(90deg, #870512, #E1111C);
  position: absolute;
  top: 0;
  height: 25vh;
  width: 100%;
  z-index: -1;
}
