@import "../mixins";

//Titulos genericos para todos los elementos

%defaultFont {
  font-family: var(--roboto);
  font-size: var(--font-xs);
  line-height: 130%;
  font-weight: var(--fw-regular);
  text-align: left;
  font-style: normal;
  color: var(--negro-800);
}

.dav-text {
  &--3xs {
    @extend %defaultFont;
    font-size: var(--font-3xs);
  }
  &--2xs {
    @extend %defaultFont;
    font-size: var(--font-2xs);
  }
  &--xs {
    @extend %defaultFont;
    font-size: var(--font-xs);
  }
  &--sm {
    @extend %defaultFont;
    font-size: var(--font-sm);
  }
  &--md {
    @extend %defaultFont;
    font-size: var(--font-md);
  }
  &--lg {
    @extend %defaultFont;
    font-size: var(--font-lg);
  }
  &--xl {
    @extend %defaultFont;
    font-size: var(--font-xl);
  }
  &--2xl {
    @extend %defaultFont;
    font-size: var(--font-2xl);
  }

  &--3xl {
    @extend %defaultFont;
    font-size: var(--font-3xl);
  }

  &--4xl {
    @extend %defaultFont;
    font-size: var(--font-4xl);
  }

  &--thin {
    font-weight: var(--fw-thin);
  }
  &--light {
    font-weight: var(--fw-light);
  }
  &--regular {
    font-weight: var(--fw-regular);
  }
  &--medium {
    font-weight: var(--fw-medium);
  }
  &--bold {
    font-weight: var(--fw-bold);
  }
  &--condensed {
    font-family: var(--roboto-condensed) !important;
  }
}

.dav-link {
  display: inline;
  text-decoration: underline;
  @extend %defaultFont;
  font-weight: var(--fw-bold);

 &:visited {
   text-decoration: underline;
 }
 &:focus {
   text-decoration: underline;
 }
}


.dav- {
  &divider {
    height: 1px;
    width: 100%;
    background-color: var(--negro-200);
    margin: 0 auto;
  }
}

.dav-buttonReadMore {
  @extend %defaultFont;
  font-size: var(--font-3xs);
  font-weight: var(--fw-medium);
  text-decoration: underline;
  cursor: pointer;
}

.dav-truncateText {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
