.swiper-pagination {
  display: inline-block;
  width: auto;
  text-align: center;
  position: relative;
  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    background-color: #d8d8d8;
    opacity: 100%;
    margin: 0 3px;
    border-radius: 30px;

    transition-duration: 0.3s;
    &-active {
      width: 16px;
      background-color: var(--rojo-00);
    }
  }
  &__none{
    display: none;
  }
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  // text-align: center;
  font-size: 18px;
  background: #fff;
  border-radius: 8px;

  height: auto;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  //display: -webkit-flex;
  //display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.dav-swiper-container {
  display: flex;
  flex-direction: column-reverse;
  width: auto;
  //height: 176px;
  @media (max-width: 500px) {
    width: auto;
  }
}



.flat {

  flex-direction: column;
  gap: 8px;
  .swiper-pagination {
    display: flex;
    .swiper-pagination-bullet {
      width: 100%;
      height: 3px;
      background-color: #d8d8d8;
      opacity: 100%;
      margin: 0 3px;
      border-radius: 30px;

      transition-duration: 0.3s;
      &-active {
        width: 100%;
        background-color: var(--rojo-00);
      }
    }
  }
}

.scale{
  .swiper-wrapper{
    .swiper-slide.swiper-slide-next {
      transform: scale(0.8);
      transition: transform 0.4s;
    }

    .swiper-slide.swiper-slide-prev {
      transform: scale(0.8);
      transition: transform 0.1s;
    }
  }
}

.heightauto{
  height: auto;
}
