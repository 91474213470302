.dav{
    &-formContainer{
        display: grid;
        gap: 16px;
    }
    &-containerComponent{
        display: flex;
        justify-content: flex-start;
        gap: 8px;
        &-horizontal{
            flex-direction: column;
        }
    }
}

.dav-buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  width: 100%;
  text-align: center;

  &--row{
    display: flex;
    gap: 8px;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  > p {
    @extend %defaultFont;
    font-size: var(--font-2xs);
  }
}

