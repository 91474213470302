//-------------------------------- Variables

//Charset
@charset "UTF-8";

:root{
  --fw-thin: 100;
  --fw-light: 300;
  --fw-regular: 400;
  --fw-medium: 500;
  --fw-bold: 700;
  --fw-black: 900;

  --rojo-00: rgba(225, 17, 28, 1); //#E1111C
  --rojo-300: rgba(225, 63, 72, 1); //#E13F48
  --rojo-301: rgba(255, 89, 71, 1); //#FF5947
  --rojo-400: rgba(253, 49, 33, 1); //#FD3121
  --rojo-500: rgba(239, 36, 34, 1); //#EF2422
  --rojo-600: rgba(221, 20, 29, 1); //#DD141D
  --rojo-700: rgba(208, 3, 21, 1); //#D00315
  --rojo-800: rgba(194, 0, 3, 1); //#C20003
  --rojo-900: rgba(183, 4, 18, 1); //#B70412
  --negro-00: rgba(0, 0, 0, 1); //#000000
  --negro-200: rgba(217, 217, 217, 1); //#D9D9D9
  --negro-300: rgba(202, 202, 202, 1); //#CACACA
  --negro-400: rgba(173, 173, 173, 1); //#ADADAD
  --negro-500: rgba(131, 131, 131, 1); //#838383
  --negro-600: rgba(110, 110, 110, 1); //#6E6E6E
  --negro-700: rgba(78, 78, 78, 1); //#4E4E4E
  --negro-800: rgba(64, 64, 64, 1); //#404040
  --negro-900: rgba(44, 44, 44, 1); //#2C2C2C
  --azul-00: rgba(4, 125, 178, 1); //#047DB2
  --azul-300: rgba(122, 203, 235, 1); //#7ACBEB
  --azul-400: rgba(69, 183, 226, 1); //#45B7E2
  --azul-500: rgba(0, 153, 217, 1); //#0099D9
  --azul-600: rgba(0, 140, 203, 1); //#008CCB
  --azul-700: rgba(0, 122, 184, 1); //#007AB8
  --azul-800: rgba(0, 106, 165, 1); //#006AA5
  --azul-900: rgba(0, 75, 133, 1); //#004B85
  --amarillo-00: rgba(255, 203, 36, 1); //#004B85
  --amarillo-800: rgba(249, 240, 182, 1); //#F9F0B6
  --amarillo-900: rgba(255, 224, 29, 1); //#FFE01D
  --naranja-00: rgba(233, 106, 8, 1); //#E96A08
  --naranja-600: rgba(224, 199, 51, 1); //#E0C733
  --naranja-700: rgba(222, 177, 42, 1); //#DEB12A
  --naranja-800: rgba(219, 154, 32, 1); //#DB9A20
  --naranja-900: rgba(213, 116, 14, 1); //#D5740E
  --naranja-1000: rgba(191, 83, 0, 1); //#BF5300
  --verde-00: rgba(2, 136, 70, 1); //#028846
  --verde-500: rgba(130, 240, 159, 1); //#82F09F
  --verde-600: rgba(77, 189, 112, 1); //#4DBD70
  --verde-700: rgba(2, 140, 68, 1); //#028C44
  --verde-800: rgba(62, 139, 68, 1); //#3E8B44
  --verde-900: rgba(1, 109, 56, 1); //#016D38
  --verde-1000: rgba(7, 91, 50, 1); //#075B32
  --gris-001: rgba(170, 177, 188, 1); //#AAB1BC
  --gris-002: rgba(193, 198, 207, 1); //#C1C6CF
  --gris-003: rgba(209, 213, 224, 1); //#D1D5E0
  --gris-004: rgba(223, 226, 237, 1); //#DFE2ED
  --gris-005: rgba(241, 242, 248, 1); //#F1F2F8
  --gris-006: rgba(250, 251, 255, 1); //#FAFBFF
  --gris-007: rgba(255, 255, 255, 1); //#FFFFFF


  --background-body: #ebecf0;
  --background-disabled: #F7F7F7;
  --background-button-quantity: #f4293323;
  --background-button-quantity--focus: #f4293346;


  --gray-b8: #B8B5B5;
  --white-fa: #FAFAFA;
  --white-fafc: #FAFAFC;
  --white-ee : #EEEEEE;
  --white-f0: #F0F0F3;
  --gray-ef: #EFEFEF;
  --gray-ba: #BAB9B9;
  --gray_f5: #F5F5F5;
  --gray-6765: #676565;
  --text-disabled: #9D9D9D;
  --text-password: #575656;

  --border-disabled: #EDEDED;
  --border-options: #E5E3E3;
  --border-icon-button-small: #E9E9E9;


  //Fonts
  --roboto: 'Roboto', sans-serif;
  --roboto-condensed: 'Roboto Condensed', sans-serif;
  --font-password: 'fontello','Roboto';

  //Size Fonts
  --font-p: 14px;
  --font-h: 16px;
}

// colores

// mapa para Color
$mapCTexto: (
    ct0: var(--gris-007),
    ct1: var(--negro-800),
    ct2: var(--negro-00),
    ct3: var(--negro-300),
    ct4: var(--gray-6765)
);

// mapa para background color
$mapBGColor: (
    bgc1: var(--background-body),
    bgc2: var(--gris-007),
    bgc3: var(--gris-007)
);

// mapa font Size
$mapFontFamily: (
    ff-r:   var(--roboto),
    ff-rc:  var(--roboto-condensed)
);

// mapa para font-weight
$mapFW: (
    thin: 100,
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
    "black": 900
);

/* Media Queries */
$media_queries : (
    xs: '320px 360px',
    s: '361px',
    desktop: '550px'
);

$mobile-breakpoint: 768px;
$desktop-breakpoint: 1024px;
$showMenu-breakpoint: 1200px;
